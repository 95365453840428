import React from "react"
import { Link } from "gatsby"

import Layout from "../../../../components/layout"
import SEO from "../../../../components/seo"

import BookingForm from "../../../../components/dynamic/BookingForm"

function SundownerTourFromQueenstown(props) {
  return (
    <>
      <SEO title="Milford Sound Sundowner Tour From Queenstown - awesomeNZ" />
      <Layout
        layoutType="typography TourPage"
        slideshow={
          <ul class="slideshow-slides">
            <li class="slideshow-slide first">
              <img
                src={require("../../../../images/sliders/milford-sound-day-tours.jpg")}
                alt=""
                class="first image"
              />
            </li>
            <li class="slideshow-slide">
              <img
                src={require("../../../../images/tours/awesomenz-milford-sound-cruise-deck.jpg")}
                alt=""
                class="image"
              />
            </li>
            <li class="slideshow-slide">
              <img
                src={require("../../../../images/sliders/milfordsound-tourist-jump.jpg")}
                alt=""
                class="last image"
              />
            </li>
          </ul>
        }
        hasSliderHandles={true}
        hasSliderBlurLarge={true}
        content={
          <>
            <BookingForm tourCode="AWZMZSD" />

            <div id="row" class="fourteen clearfix">
              <div class="title-div clearfix">
                <h1>Milford Sound Sundowner Tour From Queenstown</h1>
                <h3>
                  See famous Milford Sound on our new later tour departure with
                  an afternoon cruise
                </h3>
                <div class="highlights">
                  <ul>
                    <li>1 hour 30 minute Milford Sound cruise</li>
                    <li>Photo stops along the scenic Milford Road</li>
                    <li>Glass roof coaches with FREE Wi-Fi</li>
                    <li>FREE hotel pick ups and drop-offs</li>
                  </ul>
                </div>
              </div>
              <div id="Overview" class="clearfix">
                <div id="Content" class="clearfix">
                  <div id="ContentTab">
                    <p>
                      <strong>UPDATE:</strong>
                      <span>
                        &nbsp;
                        <em>
                          The Milford Road is now open to tour coaches
                          travelling in convoy.&nbsp;There will be limited photo
                          stops on the way to Milford Sound: Mirror Lakes and
                          Eglinton Valley will be the only planned stops. If
                          time allows we may make additional stops.
                        </em>
                      </span>
                    </p>
                    <p>
                      Sleep in and enjoy Milford Sound a little later in the day
                      with our new summer tour departure option.
                    </p>
                    <p>
                      Set off from Queenstown at 9.10am (compared to our usual
                      departure time of 7.05am) and travel in our comfy coach
                      along Lake Wakatipu and through the Southland countryside,
                      stopping in Te Anau for a meal and rest break. Your
                      awesome driver guide will make your day with entertaining
                      commentary as you travel.
                    </p>
                    <p>
                      From Te Anau we make our way into the remote Fiordland
                      National Park, a place of dense native rainforest, huge
                      mountain peaks and tranquil glacier lakes. We'll make
                      plenty of stops along the way at places like the Eglinton
                      Valley, Mirror Lakes and Monkey Creek, so you can take
                      photos and enjoy this spectacular part of New Zealand.
                    </p>
                    <p>
                      A quick trip through the Homer Tunnel (and under the
                      Southern Alps) and you'll be on your way to Milford Sound
                      for an afternoon cruise on the famous fiord. It's less
                      crowded at this time so you can enjoy an even more
                      peaceful atmosphere as you take in the views of
                      waterfalls, mighty alps, sheer rock cliffs and inky dark
                      waters.
                    </p>
                    <p>
                      After your 1 hour 30 minute Milford Sound cruise, it's
                      time to board your coach for the return trip to
                      Queenstown, arriving while the night's still young.
                    </p>
                    <p>
                      <strong>
                        This tour is available from 1 December to 31 March.
                      </strong>
                    </p>
                    <div class="clear"></div>
                  </div>
                </div>
              </div>
              <div class="GSRelatedProducts clearfix">
                <h2 class="tour">Tours You May Also Like</h2>
                <div class="tour-item">
                  <Link to="/home/new-zealand-tours/milford-sound-tours/milford-sound-tour-from-te-anau">
                    <div class="tour-image">
                      <img
                        alt=""
                        src={require("../../../../images/thumbs/milford_ex_te_anau.jpg")}
                      />
                    </div>
                  </Link>
                  <div class="tour-details">
                    <h2>Milford Sound Tour From Te Anau</h2>
                    <div class="tour-details-prices">
                      <span class="tour-details-prices-from">from </span>{" "}
                      <span class="tour-details-prices-price">$165.00</span>
                    </div>
                    <span class="clearfix">
                      <Link to="/home/new-zealand-tours/milford-sound-tours/milford-sound-tour-from-te-anau">
                        Find out more &gt;
                      </Link>
                    </span>
                  </div>
                </div>
                <div class="tour-item">
                  <Link to="/home/new-zealand-tours/milford-sound-tours/milford-sound-day-tour-from-queenstown">
                    <div class="tour-image">
                      <img
                        alt=""
                        src={require("../../../../images/tours/awesomenz-milford-sound-cruise-passengers.jpg")}
                      />
                    </div>
                  </Link>
                  <div class="tour-details">
                    <h2>Milford Sound Sundowner Tour</h2>
                    <div class="tour-details-prices">
                      <span class="tour-details-prices-from">from </span>{" "}
                      <span class="tour-details-prices-price">$189.00</span>
                    </div>
                    <span class="clearfix">
                      <Link to="/home/new-zealand-tours/milford-sound-tours/milford-sound-day-tour-from-queenstown">
                        Find out more &gt;
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
      />
    </>
  )
}

export default SundownerTourFromQueenstown
